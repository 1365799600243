import api from "./api";

class mailService {
    async send(payload) {
        return await new Promise((resolve, reject) => {
            api.post('/mail-service', payload)
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}


export default new mailService();
<template>
    <v-card class="col-lg-6 mx-auto glass--bg">
        <v-card-title>Mailformular (virker)</v-card-title>
        <v-card-text>
            <v-form>
                <v-text-field
                    v-model="mail.to"
                    label="modtager"
                    autocomplete="email"
                    dense
                    outlined/>
                <v-text-field
                    v-model="mail.subject"
                    label="Emne" dense
                    outlined/>
                <v-textarea
                    v-model="mail.body"
                    label="Besked"
                    dense
                    outlined/>
            </v-form>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    @click="sendMail()"
                    outlined
                    :loading="sending"
                    :disabled="sending"
                >Send
                </v-btn>
            </v-card-actions>
        </v-card-text>
    </v-card>
</template>

<script>
    import mailService from "../../services/mailService";

    export default {
        name: "mailForm",
        data() {
            return {
                mail: {
                    subject: '',
                    to: '',
                    body: '',
                    response: ''
                },
                sending: false,
            }
        },
        methods: {
            sendMail() {
                if (this.mail.to === '') {
                    return
                }
                this.sending = true
                mailService.send({
                    to: this.mail.to,
                    subject: this.mail.subject,
                    body: this.mail.body,
                })
                    .then(response => {
                        this.mail.response = response.data
                        this.mail.to = ''
                        this.$store.dispatch('toasts/toastSuccess', response.data.message)
                        this.sending = false
                    })
                    .catch(response => {
                        this.$store.dispatch('toasts/toastError', response.data.message)
                        this.sending = false
                    })
            }
        },

    }
</script>

<style scoped>

</style>
<template>
    <v-container>
        <v-text-field
            v-model="cvr"
        ></v-text-field>
        <v-btn @click="fetchCvr()">Hent CVR data</v-btn>
        <v-card>
            <v-card-text>
                {{result}}
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import api from "../../services/api";

    export default {
        name: "cvrApi",
        data(){
            return {
                cvr:32653308,
                result:[]
            }
        },
        methods:{
            fetchCvr(){
                api.get('cvr/'+this.cvr)
                .then(response => {
                    console.log(response.data)
                    this.result = response.data
                })
            }
        }
    }
</script>

<style scoped>

</style>
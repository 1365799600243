class ipnordic {
    period = "Period?"
    daily = "https://api-prod01.ipnordic.dk/api/Statistics/CDR/v2/TotalAgentData?"

    username = "api@kompetencekanalen"
    password = "Y*h2WqP-5Xqd^??ywjc@^E5a"

    async getList() {
        let startDate = "2021-09-29"
        let endDate = "2021-10-02"
        let dateRange = `startDate=${startDate}&endDate=${endDate}`
        return await new Promise((resolve, reject) => {
            let xml = new XMLHttpRequest();

            xml.open("GET", "https://api-prod01.ipnordic.dk/api/Statistics/CDR/v2/MobileCalls?startDate=2021-09-20&endDate=2021-09-29", false);
            xml.setRequestHeader("Authorization", "Basic " + btoa(this.username + ":" + this.password))
            xml.send()
            let data = JSON.parse(xml.responseText)
            resolve(data)
        })
    }


}

export default new ipnordic()
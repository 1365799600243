<template>
    <v-container class="pt-12">
        <div class="col-lg-6 mx-auto glass--bg">
            <v-btn @click="getCurl()">hent tlf opkald</v-btn>
            <v-data-table
                    :items="testData"
                    :headers="headers"
                    dense
            >
                <template v-slot:item.MSISDN="{item}">
                    {{caller(item)}}
                </template>
            </v-data-table>
        </div>
        <v-row>
            <v-col>
                <v-card class="glass--bg">
                    <v-card-title>w{{user.name}}</v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                    label="username"
                                    outlined
                                    dense
                                    v-model="user.name"
                            />
                            <v-text-field
                                    dense
                                    outlined
                                    label="Adgangskode"
                                    v-model="user.email"
                                    :type="hidePass ? 'password' : 'text'"
                                    :append-icon="hidePass ? 'mdi-eye-off':'mdi-eye'"
                                    @click:append="hidePass = !hidePass"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="pa-4">
                        <v-spacer/>
                        <v-btn
                                color="primary"
                                @click="getUser"
                        >Login
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-layout wrap justify-space-between>
            <mail-form/>
            <div class="col-lg-4 mx-auto glass--bg">
                <cvr-api/>
            </div>

        </v-layout>
    </v-container>
</template>

<script>
    import CvrApi from "../components/company/cvrApi";
    import ipnordic from "../services/ipnordic";
    import api from "../services/api";
    import mailService from "../services/mailService";
    import MailForm from "../components/mail/mailForm";

    export default {
        name: "test",
        components: {MailForm, CvrApi},
        data() {
            return {
                hidePass: false,
                user: {
                    name: '',
                    email: '',
                },
                calls: [],
                headers: [
                    {text: 'type', value: 'CallTypeGroup'},
                    {text: 'Varighed', value: 'Duration'},
                    {text: 'Fra', value: 'MSISDN'},
                    {text: 'Til', value: 'ToNumber'},
                ],
                timespent: '',
                testData: [{
                    "CallTime": "01-12-2021 01:56:34",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 959,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428789",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.129.222.238"
                }, {
                    "CallTime": "01-12-2021 00:42:26",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 4733,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426828",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.138.202.252"
                }, {
                    "CallTime": "01-12-2021 00:52:10",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 32024,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427975",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.168.59.16"
                }, {
                    "CallTime": "01-12-2021 01:01:25",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 160729,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425859",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.35.185.110"
                }, {
                    "CallTime": "01-12-2021 08:26:33",
                    "CallTypeGroup": "Telefoni mobilsvar",
                    "DestinationName": "Denmark",
                    "Duration": 20,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427312",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni mobilsvar",
                    "ToNumber": "309060xx"
                }, {
                    "CallTime": "01-12-2021 08:27:12",
                    "CallTypeGroup": "SMS\/MMS",
                    "DestinationName": "Denmark",
                    "Duration": 1,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427312",
                    "Operator": "Denmark",
                    "ServiceCode": "SMS",
                    "ToNumber": "266450xx"
                }, {
                    "CallTime": "01-12-2021 08:57:49",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 9,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425465",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "557220xx"
                }, {
                    "CallTime": "01-12-2021 09:00:59",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 6,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425465",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "861362xx"
                }, {
                    "CallTime": "01-12-2021 09:08:14",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 1,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427527",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "424289xx"
                }, {
                    "CallTime": "01-12-2021 09:14:59",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 2,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421487",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424270xx"
                }, {
                    "CallTime": "01-12-2021 09:17:54",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 16,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427975",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "931080xx"
                }, {
                    "CallTime": "01-12-2021 09:15:42",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 194,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427885",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "303295xx"
                }, {
                    "CallTime": "01-12-2021 09:19:13",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 5,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427975",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "225982xx"
                }, {
                    "CallTime": "01-12-2021 09:35:35",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 521,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421487",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "751707xx"
                }, {
                    "CallTime": "01-12-2021 09:26:38",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 1489,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "302238xx"
                }, {
                    "CallTime": "01-12-2021 09:51:15",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 7,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "227328xx"
                }, {
                    "CallTime": "01-12-2021 10:00:49",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 3,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425859",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "441241xx"
                }, {
                    "CallTime": "01-12-2021 10:01:49",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 52,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426297",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "938345xx"
                }, {
                    "CallTime": "01-12-2021 00:38:59",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 24061,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427312",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.0.123.241"
                }, {
                    "CallTime": "01-12-2021 10:44:48",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 196,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428768",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424270xx"
                }, {
                    "CallTime": "01-12-2021 10:47:05",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 94,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428354",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "429122xx"
                }, {
                    "CallTime": "01-12-2021 10:42:50",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 441,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426828",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "519066xx"
                }, {
                    "CallTime": "01-12-2021 11:06:16",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 2,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427885",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "402505xx"
                }, {
                    "CallTime": "01-12-2021 10:50:56",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 1356,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426828",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424250xx"
                }, {
                    "CallTime": "01-12-2021 11:21:00",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 79,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428768",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424270xx"
                }, {
                    "CallTime": "01-12-2021 09:40:05",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 79086,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428354",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.131.22.121"
                }, {
                    "CallTime": "01-12-2021 11:17:24",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 772,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426828",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "507322xx"
                }, {
                    "CallTime": "01-12-2021 11:21:32",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 541,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42429120",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "696940xx"
                }, {
                    "CallTime": "01-12-2021 11:27:14",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 342,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "228040xx"
                }, {
                    "CallTime": "01-12-2021 11:33:24",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 83,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421487",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "696696xx"
                }, {
                    "CallTime": "01-12-2021 11:40:56",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 203,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428789",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "337014xx"
                }, {
                    "CallTime": "01-12-2021 11:32:10",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 846,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427885",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "298639xx"
                }, {
                    "CallTime": "01-12-2021 11:39:19",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 639,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427059",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424287xx"
                }, {
                    "CallTime": "01-12-2021 11:44:11",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 499,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427473",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "521117xx"
                }, {
                    "CallTime": "01-12-2021 11:52:01",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 58,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428768",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424270xx"
                }, {
                    "CallTime": "01-12-2021 12:16:29",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 719,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426828",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "719929xx"
                }, {
                    "CallTime": "01-12-2021 12:33:52",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 4,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427312",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "226722xx"
                }, {
                    "CallTime": "01-12-2021 12:30:31",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 301,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426297",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "204663xx"
                }, {
                    "CallTime": "01-12-2021 12:36:11",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 227,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421487",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "209383xx"
                }, {
                    "CallTime": "01-12-2021 12:28:44",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 1022,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426828",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "405211xx"
                }, {
                    "CallTime": "01-12-2021 07:05:24",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 31519975,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428789",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.129.222.238"
                }, {
                    "CallTime": "01-12-2021 12:55:02",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 319,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426828",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "719929xx"
                }, {
                    "CallTime": "01-12-2021 13:00:51",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 6,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428768",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424270xx"
                }, {
                    "CallTime": "01-12-2021 13:01:21",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 243,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426828",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "405211xx"
                }, {
                    "CallTime": "01-12-2021 13:11:22",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 3,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "403030xx"
                }, {
                    "CallTime": "01-12-2021 13:12:28",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 3,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "242449xx"
                }, {
                    "CallTime": "01-12-2021 10:47:07",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 21549,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428354",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.133.240.88"
                }, {
                    "CallTime": "01-12-2021 00:33:54",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 344732,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421487",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.5.55.124"
                }, {
                    "CallTime": "01-12-2021 11:40:16",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 6891,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428789",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.34.110.92"
                }, {
                    "CallTime": "01-12-2021 11:41:44",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 6906,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428789",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.131.93.186"
                }, {
                    "CallTime": "01-12-2021 13:28:44",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 2,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "403030xx"
                }, {
                    "CallTime": "01-12-2021 13:29:09",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 188,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421487",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424268xx"
                }, {
                    "CallTime": "01-12-2021 13:34:55",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 91,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "594417xx"
                }, {
                    "CallTime": "01-12-2021 13:39:12",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 4,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "202100xx"
                }, {
                    "CallTime": "01-12-2021 13:45:24",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 105,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424214xx"
                }, {
                    "CallTime": "01-12-2021 13:46:09",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 425,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428768",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424270xx"
                }, {
                    "CallTime": "01-12-2021 14:06:49",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 3,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "226130xx"
                }, {
                    "CallTime": "01-12-2021 14:07:24",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 277,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "229851xx"
                }, {
                    "CallTime": "01-12-2021 14:18:31",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 144,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "877790xx"
                }, {
                    "CallTime": "01-12-2021 14:52:25",
                    "CallTypeGroup": "Telefoni mobilsvar",
                    "DestinationName": "Denmark",
                    "Duration": 16,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42423826",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni mobilsvar",
                    "ToNumber": "309060xx"
                }, {
                    "CallTime": "01-12-2021 04:41:02",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 776396,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427975",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.168.59.16"
                }, {
                    "CallTime": "01-12-2021 01:33:55",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 1927,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42423826",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.165.21.246"
                }, {
                    "CallTime": "01-12-2021 11:35:25",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 68300,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421487",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.128.6.43"
                }, {
                    "CallTime": "01-12-2021 14:54:10",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 416,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42423826",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "616285xx"
                }, {
                    "CallTime": "01-12-2021 15:01:55",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 3,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421487",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "234937xx"
                }, {
                    "CallTime": "01-12-2021 15:02:40",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 156,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427473",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "691414xx"
                }, {
                    "CallTime": "01-12-2021 15:06:06",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 229,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427473",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "452750xx"
                }, {
                    "CallTime": "01-12-2021 15:10:11",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 64,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427473",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "691414xx"
                }, {
                    "CallTime": "01-12-2021 15:23:47",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 159,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "325782xx"
                }, {
                    "CallTime": "01-12-2021 15:25:48",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 217,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427473",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "452750xx"
                }, {
                    "CallTime": "01-12-2021 14:38:29",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 112,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42423826",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.165.21.246"
                }, {
                    "CallTime": "01-12-2021 07:34:34",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 12948590,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421496",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.167.104.138"
                }, {
                    "CallTime": "01-12-2021 17:00:48",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 14204,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42423826",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.165.21.246"
                }, {
                    "CallTime": "01-12-2021 23:10:41",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 578,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428789",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.0.183.90"
                }, {
                    "CallTime": "01-12-2021 00:04:22",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 666197,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425056",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.138.253.181"
                }, {
                    "CallTime": "01-12-2021 00:05:12",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 158879,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427885",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.128.97.41"
                }, {
                    "CallTime": "01-12-2021 00:30:34",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 541040,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426095",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.138.185.91"
                }, {
                    "CallTime": "01-12-2021 10:19:28",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 31996,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426828",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.138.202.252"
                }, {
                    "CallTime": "01-12-2021 13:43:43",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 139207,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421487",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.128.6.43"
                }, {
                    "CallTime": "01-12-2021 00:42:23",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 21995,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425465",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.35.104.43"
                }, {
                    "CallTime": "01-12-2021 08:27:13",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 3761012,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427312",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.7.10.200"
                }, {
                    "CallTime": "01-12-2021 00:38:49",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 1807194,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.163.123.201"
                }, {
                    "CallTime": "01-12-2021 00:53:23",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 4431829,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42420568",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.6.139.229"
                }, {
                    "CallTime": "01-12-2021 00:58:32",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 544910,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42429120",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.37.29.43"
                }, {
                    "CallTime": "01-12-2021 04:49:57",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 9759684,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.4.221.13"
                }, {
                    "CallTime": "01-12-2021 00:59:24",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 602306,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427059",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.165.108.28"
                }, {
                    "CallTime": "01-12-2021 01:03:24",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 44853,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427527",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.40.48.10"
                }, {
                    "CallTime": "01-12-2021 06:24:59",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 471959,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425859",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.35.185.110"
                }, {
                    "CallTime": "01-12-2021 12:59:21",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 352962,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427975",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.34.62.53"
                }, {
                    "CallTime": "01-12-2021 00:57:13",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 700228,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427473",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.168.231.232"
                }, {
                    "CallTime": "01-12-2021 01:10:12",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 593519,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426225",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.8.176.150"
                }, {
                    "CallTime": "02-12-2021 02:40:07",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 26742,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428789",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.0.183.90"
                }, {
                    "CallTime": "02-12-2021 00:21:39",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 4298,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427312",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.7.10.200"
                }, {
                    "CallTime": "02-12-2021 02:50:45",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 4418,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428789",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.0.183.90"
                }, {
                    "CallTime": "02-12-2021 07:49:41",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 179,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426297",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "287791xx"
                }, {
                    "CallTime": "02-12-2021 09:45:42",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 11,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "596525xx"
                }, {
                    "CallTime": "02-12-2021 09:39:34",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 392,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427473",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "292816xx"
                }, {
                    "CallTime": "02-12-2021 09:48:47",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 95,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425465",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "424215xx"
                }, {
                    "CallTime": "02-12-2021 09:41:46",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 960,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427059",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "427378xx"
                }, {
                    "CallTime": "02-12-2021 00:41:31",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 7898,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426828",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.138.202.252"
                }, {
                    "CallTime": "02-12-2021 10:02:18",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 4,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427312",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "222442xx"
                }, {
                    "CallTime": "02-12-2021 10:03:37",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 2,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427312",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "811882xx"
                }, {
                    "CallTime": "02-12-2021 10:05:01",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 251,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "596525xx"
                }, {
                    "CallTime": "02-12-2021 10:10:54",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 224,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428768",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424270xx"
                }, {
                    "CallTime": "02-12-2021 10:15:18",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 4,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "227328xx"
                }, {
                    "CallTime": "02-12-2021 10:18:47",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 3,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424218xx"
                }, {
                    "CallTime": "02-12-2021 10:21:39",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 167,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "719937xx"
                }, {
                    "CallTime": "02-12-2021 10:25:46",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 224,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "292512xx"
                }, {
                    "CallTime": "02-12-2021 10:34:43",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 146,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428354",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424268xx"
                }, {
                    "CallTime": "02-12-2021 10:52:27",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 133,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426805",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "361664xx"
                }, {
                    "CallTime": "02-12-2021 10:57:04",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 5,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427059",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "317994xx"
                }, {
                    "CallTime": "02-12-2021 10:51:00",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 1041,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "427109xx"
                }, {
                    "CallTime": "02-12-2021 11:23:33",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 127,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "427109xx"
                }, {
                    "CallTime": "02-12-2021 11:23:03",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 387,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427059",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "317994xx"
                }, {
                    "CallTime": "02-12-2021 11:36:50",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 28,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "242470xx"
                }, {
                    "CallTime": "02-12-2021 11:31:39",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 372,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427059",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424287xx"
                }, {
                    "CallTime": "02-12-2021 11:38:09",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 3,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427059",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "317994xx"
                }, {
                    "CallTime": "02-12-2021 11:40:03",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 97,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "962803xx"
                }, {
                    "CallTime": "02-12-2021 11:35:48",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 715,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427473",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "261252xx"
                }, {
                    "CallTime": "02-12-2021 11:53:20",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 184,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427059",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "317994xx"
                }, {
                    "CallTime": "02-12-2021 10:45:59",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 918,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42423826",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.41.142.63"
                }, {
                    "CallTime": "02-12-2021 10:34:44",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 113897,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42428354",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.164.68.146"
                }, {
                    "CallTime": "02-12-2021 12:43:12",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 495,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42429120",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "888213xx"
                }, {
                    "CallTime": "02-12-2021 12:53:39",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Udlandet",
                    "Duration": 150,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42429120",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni intern Mobil",
                    "ToNumber": "424273xx"
                }, {
                    "CallTime": "02-12-2021 10:52:57",
                    "CallTypeGroup": "Data",
                    "DestinationName": "INTERNET",
                    "Duration": 846753,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42421899",
                    "Operator": "Denmark",
                    "ServiceCode": "Data",
                    "ToNumber": "10.136.85.119"
                }, {
                    "CallTime": "02-12-2021 13:11:19",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 1,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427473",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "452750xx"
                }, {
                    "CallTime": "02-12-2021 12:37:12",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 2209,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "214569xx"
                }, {
                    "CallTime": "02-12-2021 13:16:20",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 176,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "253289xx"
                }, {
                    "CallTime": "02-12-2021 13:18:38",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 179,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "696696xx"
                }, {
                    "CallTime": "02-12-2021 13:22:05",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 102,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426805",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "361664xx"
                }, {
                    "CallTime": "02-12-2021 13:24:23",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 4,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "719937xx"
                }, {
                    "CallTime": "02-12-2021 13:23:07",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 142,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425465",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "557220xx"
                }, {
                    "CallTime": "02-12-2021 13:10:42",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 1073,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42423826",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "962020xx"
                }, {
                    "CallTime": "02-12-2021 13:40:36",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 20,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "632120xx"
                }, {
                    "CallTime": "02-12-2021 13:40:46",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 39,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425465",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "617057xx"
                }, {
                    "CallTime": "02-12-2021 13:41:04",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 151,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "632120xx"
                }, {
                    "CallTime": "02-12-2021 13:49:55",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 78,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "363903xx"
                }, {
                    "CallTime": "02-12-2021 13:20:04",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 1919,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42427473",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "518805xx"
                }, {
                    "CallTime": "02-12-2021 13:51:19",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 143,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "363903xx"
                }, {
                    "CallTime": "02-12-2021 13:53:47",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 136,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "363903xx"
                }, {
                    "CallTime": "02-12-2021 13:46:26",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 612,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42425465",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "867600xx"
                }, {
                    "CallTime": "02-12-2021 14:01:46",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 76,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426035",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Fastnet",
                    "ToNumber": "755747xx"
                }, {
                    "CallTime": "02-12-2021 14:02:59",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 3,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "315356xx"
                }, {
                    "CallTime": "02-12-2021 14:07:09",
                    "CallTypeGroup": "Telefoni",
                    "DestinationName": "Denmark",
                    "Duration": 4,
                    "InvoiceAmount": 0.0000,
                    "MSISDN": "42426098",
                    "Operator": "Denmark",
                    "ServiceCode": "Telefoni Mobil",
                    "ToNumber": "401469xx"
                },],
                people: [
                    {'name': 'Allan Andersen', 'mail': 'allan@kompetencekanalen.dk', 'phone': '42421520',},
                    {'name': 'Benjamin Ben-Noon', 'mail': 'benjamin@kompetencekanalen.dk', 'phone': '42428980',},
                    {'name': 'Cecilie Conradsen', 'mail': 'ceciliec@kompetencekanalen.dk', 'phone': '42425859',},
                    {'name': 'Erik Them Andersen', 'mail': 'erik@kompetencekanalen.dk', 'phone': '42426035',},
                    {'name': 'Gabriel Keuer Jeppesen', 'mail': 'gabriel@kompetencekanalen.dk', 'phone': '42426389',},
                    {'name': 'Henrik Vincent Nielsen', 'mail': 'hvi@kompetencekanalen.dk', 'phone': '42428354',},
                    {'name': 'Jacob Arnvel', 'mail': 'jacob@kompetencekanalen.dk', 'phone': '42429120',},
                    {'name': 'Jacob Ilsø Jensen', 'mail': 'jij@kompetencekanalen.dk', 'phone': '42421890',},
                    {'name': 'Jakob Knudsen', 'mail': 'jkn@kompetencekanalen.dk', 'phone': '42421487',},
                    {'name': 'Line Funch Rabek', 'mail': 'lfr@kompetencekanalen.dk', 'phone': '42425056',},
                    {'name': 'Louise Bech', 'mail': 'louiseb@kompetencekanalen.dk', 'phone': '42427527',},
                    {'name': 'Mads Peter Winding', 'mail': 'mpw@kompetencekanalen.dk ', 'phone': '42420568',},
                    {'name': 'Mads Pilegaard', 'mail': 'mads@kompetencekanalen.dk', 'phone': '42427312',},
                    {'name': 'Martin Thornvig', 'mail': 'martin@kompetencekanalen.dk', 'phone': '42428768',},
                    {'name': 'Martin Vendelbo', 'mail': 'mve@kompetencekanalen.dk', 'phone': '42427473',},
                    {'name': 'Mathias Hougaard', 'mail': 'mathias@kompetencekanalen.dk', 'phone': '42421520',},
                    {'name': 'Mathias Jørgensen', 'mail': 'mlj@kompetencekanalen.dk', 'phone': '42426297',},
                    {'name': 'Max Jakobsen', 'mail': 'max@kompetencekanalen.dk', 'phone': '42426098',},
                    {'name': 'Mikkel Simonsen', 'mail': 'msi@kompetencekanalen.dk', 'phone': '42425859',},
                    {'name': 'Morten Hebsgaard Andersen', 'mail': 'morten@kompetencekanalen.dk', 'phone': '42427059',},
                    {'name': 'Morten Lavridsen', 'mail': 'mla@kompetencekanalen.dk ', 'phone': '42423826',},
                    {'name': 'Rasmus Nielsen', 'mail': 'rasmus@kompetencekanalen.dk', 'phone': '42426866',},
                    {'name': 'Rebecca Lind Christensen', 'mail': 'rebecca@kompetencekanalen.dk', 'phone': '42421496',},
                    {'name': 'Rikke Steiniche', 'mail': 'rikke@kompetencekanalen.dk', 'phone': '42426828',},
                    {'name': 'Sascha Broen Kampff', 'mail': 'sascha@kompetencekanalen.dk', 'phone': '42427975',},
                    {'name': 'Selina Runoberg Junker', 'mail': 'selina@kompetencekanalen.dk', 'phone': '42426095',},
                    {'name': 'Simon Gottlieb Møller', 'mail': 'sgm@kompetencekanalen.dk', 'phone': '42427885',},
                    {'name': 'Simone Strandet Sørensen', 'mail': 'simone@kompetencekanalen.dk', 'phone': '42426225',},
                    {'name': 'Souade Nadia Braikia', 'mail': 'souade@kompetencekanalen.dk ', 'phone': '42421868',},
                    {'name': 'Michael Kent Christensen', 'mail': 'michael@kompetencekanalend.k', 'phone': '42425465',}
                ]
            }
        },
        methods: {
            getCurl() {
                api.get('/ipnordic')
                    .then(response => {
                        console.log(response.data)
                        return response.data
                    })
                    .then(this.callFilter)
                    .then(calls => {
                        this.calls = calls
                    })

            },
            callFilter(calls) {
                return calls.filter(x => x.CallTypeGroup === "Telefoni")
            },
            getUser() {
                api.get('users/deleted')
                    .then(response => {
                        console.log(response.data)
                        this.user.name = response.data.name
                        this.user.email = response.data.mail
                    })
            },
            internalPhoneNumbers() {
                return this.testData.map(x => x.MSISDN).filter((value, index, self) => {
                    return self.indexOf(value) === index
                })
            },

            callCounter() {
                this.people.forEach((person, idx) => {
                    this.people[idx].outgoing = this.testData.filter(x => (x.MSISDN === person.phone))
                })
            },
            caller(item) {
                let person = this.people.filter(x => x.phone == item.MSISDN)

                return person.length >= 1 ? person[0].name : item.MSISDN
            }
        },
        mounted() {
            this.callCounter()
        },
        computed: {}
    }


    let json = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Books",
            "item": "https://example.com/books"
        }, {
            "@type": "ListItem",
            "position": 2,
            "name": "Science Fiction",
            "item": "https://example.com/books/sciencefiction"
        }, {
            "@type": "ListItem",
            "position": 3,
            "name": "Award Winners"
        }]
    }
</script>


<style>
</style>